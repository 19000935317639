<template>
  <div class="mx-4 mt-4">
    <div class="card col-12">
      <div class="mt-4">
        <supply-rule-widget-table :is-widget="true" />
      </div>
    </div>
  </div>
</template>
<script>
// Components
import SupplyRuleWidgetTable from "./partials/SupplyRuleWidgetTable";

export default {
  components: {
    SupplyRuleWidgetTable,
  },
  data() {
    return {};
  },
};
</script>
<style></style>
